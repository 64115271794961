.heading {
  display: flex;
  justify-content: center;
}

.list-buttons {
  list-style: none;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border-color: black;
  border-width: 2px;
  margin-right: 35px;
}

.income-homepage {
  width: 100%;
  height: 350px;
  background-color: #668925;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-radius: 12px;
  margin-top: 45px;
}

.expenses-homepage {
  width: 100%;
  height: 350px;
  background-color: #e33e33;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-radius: 12px;
  margin-top: 20px;
}

.homepage-heading {
  color: #000805;
}
